var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"穿衣交接确认","width":"600px"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"wrapper-col":{ span: 12 }}},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._v("请与客户仔细沟通以下交接事项：")])]),_c('a-form-item',{attrs:{"label":"逝者姓名"}},[_vm._v(" "+_vm._s(_vm.deathInfo.name)+" ")]),_c('a-form-item',{attrs:{"label":"性别"}},[_vm._v(" "+_vm._s(_vm.deathInfo.sex)+" ")]),_c('a-form-item',{attrs:{"label":"逝世原因"}},[_vm._v(" "+_vm._s(_vm.deathInfo.dead_reason)+" ")]),_c('a-form-item',{attrs:{"label":"接运地点"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.origination)+" ")]),_c('a-form-item',{attrs:{"label":"遗体是否有伤口破损"}},[_vm._v(" "+_vm._s(_vm.deathInfo.Damaged? _vm.deathInfo.damage_desc : '无')+" ")]),_c('a-form-item',{attrs:{"label":"遗体身上是否有贵重物品"}},[_vm._v(" "+_vm._s(_vm.deathInfo.has_valuables? _vm.deathInfo.valuables_content : '无')+" ")]),_c('a-form-item',{attrs:{"label":"厅房名称"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.hall_name)+" ")]),_c('a-form-item',{attrs:{"label":"服务标准"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.package_name)+" ")]),_c('a-form-item',{attrs:{"label":"进厅时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['to_hall_time', {
            rules: [
              { required: true, message: "请选择进厅时间" }
            ],
          }]),expression:"['to_hall_time', {\n            rules: [\n              { required: true, message: `请选择进厅时间` }\n            ],\n          }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' },"placeholder":"请选择时间"},on:{"openChange":_vm.handleStartOpenChange}})],1),_c('a-form-item',{attrs:{"label":"出厅时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['out_hall_time', {
            rules: [
              { required: true, message: "请选择出厅时间" }
            ],
          }]),expression:"['out_hall_time', {\n            rules: [\n              { required: true, message: `请选择出厅时间` }\n            ],\n          }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' },"placeholder":"请选择时间"},on:{"openChange":_vm.handleEndOpenChange}})],1),_c('a-form-item',{attrs:{"label":"寿服"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.longevity_cloth_info)+" ")]),_c('a-form-item',{attrs:{"label":"寿棺"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.coffin_info)+" ")]),_c('a-form-item',{attrs:{"label":"遗体SPA"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.body_spa)+" ")]),_c('a-form-item',{attrs:{"label":"其他服务项目"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['other_service_items']),expression:"['other_service_items']"}],staticStyle:{"margin-top":"10px"}},[_c('a-row',_vm._l((_vm.taskOtherServiceItemOptions),function(item,index){return _c('a-col',{key:index,attrs:{"span":6}},[_c('a-checkbox',{attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1)],1)],1),_c('a-form-item',{attrs:{"label":"妆容要求"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['makeup']),expression:"['makeup']"}]},[_c('a-radio',{attrs:{"value":"light"}},[_vm._v("淡妆")]),_c('a-radio',{attrs:{"value":"none"}},[_vm._v("素颜")])],1)],1),_c('a-form-item',{attrs:{"label":"妆容其他要求"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['makeup_others', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入妆容其他要求' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['makeup_others', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入妆容其他要求' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"遗体清洗"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['clean']),expression:"['clean']"}]}),_vm._v(" 擦拭消毒 ")],1),_c('a-form-item',{attrs:{"label":"遗体清洗其他要求"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['clean_others', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入遗体清洗其他要求' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['clean_others', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入遗体清洗其他要求' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"其他配件物品说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['other_goods', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入其他配件物品说明' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['other_goods', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入其他配件物品说明' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"穿衣入殓时间段"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dress_time', {
            rules: [
              { required: true, message: '请选择穿衣入殓时间段' } ]
          }]),expression:"['dress_time', {\n            rules: [\n              { required: true, message: '请选择穿衣入殓时间段' },\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' },"placeholder":['开始时间', '结束时间']}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            rules: [
              { required: true, message: '请输入备注' },
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            rules: [\n              { required: true, message: '请输入备注' },\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }