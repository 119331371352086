<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="穿衣交接确认"
      width="600px"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item :wrapper-col="{ span: 12 }"><div style="text-align: center">请与客户仔细沟通以下交接事项：</div></a-form-item>

        <a-form-item label="逝者姓名">
          {{ deathInfo.name }}
        </a-form-item>
        <a-form-item label="性别">
          {{ deathInfo.sex }}
        </a-form-item>
        <a-form-item label="逝世原因">
          {{ deathInfo.dead_reason }}
        </a-form-item>
        <a-form-item label="接运地点">
          {{ serviceOrder.origination }}
        </a-form-item>
        <a-form-item label="遗体是否有伤口破损">
          {{ deathInfo.Damaged? deathInfo.damage_desc : '无' }}
        </a-form-item>
        <a-form-item label="遗体身上是否有贵重物品">
          {{ deathInfo.has_valuables? deathInfo.valuables_content : '无' }}
        </a-form-item>
        <a-form-item label="厅房名称">
          {{ serviceOrder.hall_name }}
        </a-form-item>
        <a-form-item label="服务标准">
          {{ serviceOrder.package_name }}
        </a-form-item>
        <a-form-item label="进厅时间">
          <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            @openChange="handleStartOpenChange"
            :show-time="{ format: 'HH:mm' }"
            placeholder="请选择时间"
            v-decorator="['to_hall_time', {
              rules: [
                { required: true, message: `请选择进厅时间` }
              ],
            }]"
          />
        </a-form-item>
        <a-form-item label="出厅时间">
          <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            @openChange="handleEndOpenChange"
            :show-time="{ format: 'HH:mm' }"
            placeholder="请选择时间"
            v-decorator="['out_hall_time', {
              rules: [
                { required: true, message: `请选择出厅时间` }
              ],
            }]"
          />
        </a-form-item>
        <a-form-item label="寿服">
          {{ serviceOrder.longevity_cloth_info }}
        </a-form-item>
        <a-form-item label="寿棺">
          {{ serviceOrder.coffin_info }}
        </a-form-item>
        <a-form-item label="遗体SPA">
          {{ serviceOrder.body_spa }}
        </a-form-item>
        <a-form-item label="其他服务项目">
          <a-checkbox-group
            style="margin-top: 10px"
            v-decorator="['other_service_items']"
          >
            <a-row>
              <a-col
                :span="6"
                :key="index"
                v-for="(item, index) in taskOtherServiceItemOptions"
              >
                <a-checkbox
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="妆容要求">
          <a-radio-group v-decorator="['makeup']">
            <a-radio value="light">淡妆</a-radio>
            <a-radio value="none">素颜</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="妆容其他要求">
          <a-input
            v-decorator="['makeup_others', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入妆容其他要求' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="遗体清洗">
          <a-checkbox
            v-decorator="['clean']"
          />
          擦拭消毒
        </a-form-item>
        <a-form-item label="遗体清洗其他要求">
          <a-input
            v-decorator="['clean_others', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入遗体清洗其他要求' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="其他配件物品说明">
          <a-input
            v-decorator="['other_goods', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入其他配件物品说明' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="穿衣入殓时间段">
          <a-range-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            :placeholder="['开始时间', '结束时间']"
            v-decorator="['dress_time', {
              rules: [
                { required: true, message: '请选择穿衣入殓时间段' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { required: true, message: '请输入备注' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import {
  findConfirmFuneralHandover,
  confirmFuneralHandover,
  findOrderInfo,
  findServiceOrderDeathBasicInfo
} from '@/api/order'
import { formatDateTimeMin, formatRangeBeginDateTime, formatRangeEndDateTime } from '@/utils/time'
import { findTaskOtherServiceItemOptions } from '@/api/task'

export default {
  name: 'ConfirmFuneralHandover',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'confirm_funeral_handover' }),
      submitting: false,
      data: {},
      serviceOrder: {},
      deathInfo: {},
      taskOtherServiceItemOptions: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
    this.fetchServiceOrderInfo()
    this.fetchServiceOrderDeathInfo()
    this.fetchTaskOtherServiceItemOptions()
  },
  methods: {
    fetchTaskOtherServiceItemOptions() {
      findTaskOtherServiceItemOptions().then(res => {
        if (res.code === 0) {
          this.taskOtherServiceItemOptions = res.data.result
        }
      })
    },

    fetchServiceOrderInfo() {
      findOrderInfo(this.id).then(res => {
        if (res.code === 0) {
          this.serviceOrder = res.data
        }
      })
    },

    fetchServiceOrderDeathInfo() {
      findServiceOrderDeathBasicInfo(this.id).then(res => {
        if (res.code === 0) {
          this.deathInfo = res.data
        }
      })
    },

    fetchFormData() {
      const toHallTime = this.$moment().format('YYYY-MM-DD HH:mm')
      const outHallTime = this.$moment().format('YYYY-MM-DD HH:mm')
      findConfirmFuneralHandover(this.id).then((res) => {
        if (res.code === 0) {
          this.data = {
            makeup: res.data.makeup,
            makeup_others: res.data.makeup_others,
            clean: res.data.clean,
            clean_others: res.data.clean_others,
            other_goods: res.data.other_goods,
            remark: res.data.remark,
            other_service_items: res.data.other_service_items,
            to_hall_time: res.data.to_hall_time !== '' ? this.$moment(res.data.to_hall_time, 'YYYY-MM-DD HH:mm') : this.$moment(toHallTime, 'YYYY-MM-DD HH:mm'),
            out_hall_time: res.data.out_hall_time !== '' ? this.$moment(res.data.out_hall_time, 'YYYY-MM-DD HH:mm') : this.$moment(outHallTime, 'YYYY-MM-DD HH:mm')
          }
          if (res.data.start_time && res.data.end_time) {
            this.data = {
              ...this.data,
              dress_time: [this.$moment(res.data.start_time, 'YYYY-MM-DD HH:mm'), this.$moment(res.data.end_time, 'YYYY-MM-DD HH:mm')]
            }
          }

          this.form.setFieldsValue(this.data)
        }
      })
    },

    handleStartOpenChange() {
      const fieldsValue = this.form.getFieldsValue()
      const startValue = fieldsValue.to_hall_time
      const endValue = fieldsValue.out_hall_time
      if (endValue && startValue) {
        if (endValue.valueOf() < startValue.valueOf()) {
          this.endOpen = false
          this.$warning({
            title: '进厅时间不能小于出厅时间',
            content: ''
          })
          this.form.setFieldsValue({ to_hall_time: null })
        }
      }
    },
    handleEndOpenChange() {
      const fieldsValue = this.form.getFieldsValue()
      const startValue = fieldsValue.to_hall_time
      const endValue = fieldsValue.out_hall_time
      if (endValue && startValue) {
        if (endValue.valueOf() < startValue.valueOf()) {
          this.endOpen = false
          this.$warning({
            title: '出厅时间不能小于进厅时间',
            content: ''
          })
          this.form.setFieldsValue({ out_hall_time: null })
        }
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            to_hall_time: formatDateTimeMin(values.to_hall_time),
            out_hall_time: formatDateTimeMin(values.out_hall_time),
            start_time: formatRangeBeginDateTime(values.dress_time),
            end_time: formatRangeEndDateTime(values.dress_time)
          }
          confirmFuneralHandover(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
