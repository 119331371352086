import request from '@/utils/request'

// 查询任务的运送情况
export function findTaskTransport(id) {
  return request({
    url: `/tasks/` + id + `/transport`,
    method: 'get'
  })
}

// 查询任务的车辆
export function fetchTaskCar(id) {
  return request({
    url: `/tasks/${id}`,
    method: 'get'
  })
}

// 查询任务其他服务项目
export function findTaskOtherServiceItemOptions(id) {
  return request({
    url: `/tasks/task_other_service_item_options`,
    method: 'get'
  })
}
